import React from 'react';
import { Container, Grid } from '@mui/material';
import { AllImage } from '../../styling/style';
import styled from '@emotion/styled';
import Cards from '../../components/card';
import RoadMapDog from '../../assets/roadmap.png';
import roadmapHeading from '../../assets/roadmapHeading.png';
import FeetLeft from '../../assets/dogfeetleft.png';
import FeetRight from '../../assets/dogfeetright.png';

const BackgroundDiv = styled.div`
    background: linear-gradient(to top, #FB3826 10%, #030303 50%);
    min-height: 100vh;
`
const ImageGrid = styled(Grid)`
    display: flex;
    justify-content: end;
    align-items: end;
    padding: 10% 0px;
    @media (max-width: 599px){
        justify-content: center;
    }
`
const CardGrid = styled(Grid)`
    display: flex;
    justify-content: ${props => props.jc ? props.jc : ''};
    align-items: center;
    padding-bottom: 20px;
    position: relative;
`
const AllImageExtRight = styled(AllImage)`
    position: absolute;
    top: -44%;
    right: 26%;
`
const AllImageExtLeft = styled(AllImage)`
    position: absolute;
    top: -12%;
    left: 26%;
`
function RoadMap() {
    return (
        <BackgroundDiv id='roadmap'>
            <Container>
                <Grid container>
                    <Grid item xs={12} sx={{display:"grid",placeItems:"center", padding: '10% 0px 3%' }}>
                        <AllImage  mw="30%" mw600="80%" src={roadmapHeading}/>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <CardGrid item xs={12} jc='start'>
                                    <Cards
                                        tag='Phase1'
                                        data={[['- Token Launch'],
                                        ['- Website Launch'],
                                        ['- 1,000 holders'],
                                        ['- CG and CMC Listings'],
                                        ['- $WAGYU Trending on Twitter']

                                        ]}
                                    />
                                </CardGrid>
                                <CardGrid item xs={12} jc='center'>
                                    <Cards
                                        tag='Phase2'
                                        data={[['- Community Partnerships'],
                                        ['- Exchange Listings'],
                                        ['- Release Official Partnerships'],
                                        ['- Further Digital Marketing '],
                                        ['- 5000 Holders- $WAGYU Trending on Twitter']
                                        ]}
                                    />
                                    <AllImageExtRight mw='25%' src={FeetRight} />
                                </CardGrid>
                                <CardGrid item xs={12} jc='end'>
                                    <AllImageExtLeft mw='25%' src={FeetLeft} />
                                    <Cards
                                        tag='Phase3'
                                        data={[['- International Communities'],
                                        ['- Website Revisions'],
                                        ['- Meme Takeover '],
                                        ['- CG and CMC Listings'],
                                        ['- 10,000 Holders- $WAGYU Trending on Twitter']
                                        ]}
                                    />
                                </CardGrid>
                            </Grid>
                        </Grid>
                        <ImageGrid item xs={12} md={6} data-aos='zoom-in'>
                            <AllImage mw='70%' src={RoadMapDog} />
                        </ImageGrid>
                    </Grid>
                </Grid>
            </Container>
        </BackgroundDiv>
    )
}

export default RoadMap