import React, { useState } from "react";
import Dogs from '../../assets/dogs.png';
import { AllImage, Paragraph } from "../../styling/style";
import { Container, Grid, Box } from '@mui/material';
import styled from '@emotion/styled';
import CopyIcon from '../../assets/copy.png';
import Dog1 from '../../assets/dog1.png';
import Dog2 from '../../assets/dog2.png';
import Dog3 from '../../assets/dog3.png';
import Dog4 from '../../assets/dog4.png';
import Dog5 from '../../assets/dog5.png';

const ImageGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 500px;
    @media (max-width: 800px){
        display: none;
    }
`
const BackgroundDiv = styled.div`
    background: linear-gradient(to top, #F9B208 10%,#000000 40%);
`
const CardGrid = styled(Grid)`
    display: flex;
    align-items: center;
    
    flex-direction: column;
    text-align: left;
`
const ContractBox = styled(Box)`
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    max-width: 100%;
    margin: 30px 0;
    position: relative;
    ::before{
        content:"";
        position: absolute;
        width: calc(100% + .5px);
        height: 100%;
        top:20%;
        left:-1px;
        border:1px solid #fff;
    }
    @media (max-width: 599px){
        flex-direction: column;
    }
`

const ImageDog1 = styled(AllImage)`
    position: absolute;
    z-index: 1;
    top: 20%;
    right: 27%;
    transform: translate(-50%, -50%);
`
const ImageDog2 = styled(AllImage)`
    position: absolute;
    z-index: 2;
    top: 59%;
    right: 27%;
    transform: translate(-50%, -50%);
`
const ImageDog3 = styled(AllImage)`
    position: absolute;
    z-index: 3;
    top: 70%;
    right: 21%;
    transform: translate(-50%, -50%);
`
const ImageDog4 = styled(AllImage)`
    position: absolute;
    z-index: 0;
    right: 0%;
    top: 23%;
    transform: translate(-50%, -50%);
`
const ImageDog5 = styled(AllImage)`
    position: absolute;
    z-index: 4;
    left: 32%;
    top: 50%;
    transform: translate(-50%, -50%);
`
const ResImageGrid = styled(Grid)`
    display: none;
    @media (max-width: 800px){
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`
const Contract = () => {
    const [showToast, setShowToast] = useState(false);
  const addressToCopy = "00000000000000Xx0000x0000xxxx0";

  const handleCopy = () => {
    navigator.clipboard.writeText(addressToCopy);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };
    return (
        <BackgroundDiv id='tokenomics'>
            <Container maxWidth='lg'>
                <Grid container>
                    <ImageGrid item xs={12}>
                        <ImageDog1 mw='28%' src={Dog1}/>
                        <ImageDog2 mw='25%' src={Dog2}/>
                        <ImageDog3 mw='15%' src={Dog3}/>
                        <ImageDog4 mw='30%' src={Dog4}/>
                        <ImageDog5 mw='10%' src={Dog5}/>
                    </ImageGrid>
                    <ResImageGrid item xs={12} data-aos='zoom-in'>
                        <AllImage mw='100%' src={Dogs} />
                    </ResImageGrid>
                    <Grid  container item xs={12} md={8} justifyContent='center' sx={{ padding: '40px 0px', margin: 'auto' }}>
                        <CardGrid sx={{display:"flex",alignItems:'start !important'}} js='end' item xs={4} md={4}>
                            <Paragraph fontFamily="manjariBold" ta='left' data-aos="fade-up" variant="h3" fs600='20px' fs='50px' js='flex-end' fw='bold'>
                                $WAGYU
                            </Paragraph>
                            <Paragraph  fs600='16px' fs='18px' ta='left' data-aos="fade-up">
                                Symbol
                            </Paragraph>
                        </CardGrid>
                        <CardGrid item xs={4} md={4}>
                            <Paragraph ta='left' data-aos="fade-up" variant="h3" fs600='20px' fs='50px' js='center' fw='bold'>
                                100M
                            </Paragraph>
                            <Paragraph  fs600='16px' fs='18px' ta='left' data-aos="fade-up">
                                Supply
                            </Paragraph>
                        </CardGrid>
                        <CardGrid sx={{display:"flex",alignItems:'end !important'}}  item xs={4} md={4}>
                            <Paragraph ta='left' data-aos="fade-up" variant="h3" fs600='20px' fs='50px' js='flex-start' fw='bold'>
                                0/0
                            </Paragraph>
                            <Paragraph fs600='16px' fs='18px' ta='left' data-aos="fade-up">
                                Taxes
                            </Paragraph>
                        </CardGrid>
                        <Grid item xs={12} sx={{position:"relative"}}>
                            {showToast &&  <div style={{
                                width:"300px",
                                height:"40px",
                                borderRadius:"10px",
                                position:"absolute",
                                top:"0",
                                left:"50%",
                                transform:"translate(-50%,0%)",
                                display:"grid",
                                placeItems:"center",
                                background:"#000",
                                color:"#F9B208",
                                zIndex:"1"

                            }}>
                               Contract Address Copied
                            </div>}
                            <ContractBox>
                                <Paragraph fm="manjariBold" c='black' data-aos="fade-up" fs600='13px'>
                                    Contract Address:
                                </Paragraph>
                                <Paragraph fm="manjariBold" c='black' data-aos="fade-up" sx={{ display: 'flex', alignItems: 'center' }} fs600='13px'>
                                    00000000000000Xx0000x0000xxxx0
                                    <AllImage onClick={handleCopy} style={{cursor:"pointer"}} mw='20px' mw600='15px' src={CopyIcon} />
                                </Paragraph>
                            </ContractBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </BackgroundDiv>
    )
}
export default Contract;
