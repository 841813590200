import React from 'react';
import { AllImage, Paragraph } from '../../styling/style';
import { Container, Grid } from '@mui/material';
import styled from '@emotion/styled';
import FrameBack from '../../assets/frameback.png';
import FrameDog from '../../assets/framedog.png';
import buy from '../../assets/buy.png';

const IFrame = styled.iframe`
  border: 0;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
  max-width: 100%;
  min-height: calc(100vh - 50px);
`;
const GridStyle = styled(Grid)`
    margin: 5% 0% 7%;
    z-index: 1;
`
const BackgroundDiv = styled.div`
     position: relative;
    z-index: 0;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${FrameBack});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: 0;
    }
`
const ImageGrid = styled(Grid)`
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    position: relative;
`
const DogImage = styled(AllImage)`
    position: absolute;
`
function Buy() {
    return (
        <div>
            <Container maxWidth='lg' id='buy'>
                <Grid container>
                    <Grid item xs={12} sx={{display:"grid",placeItems:"center", padding: '10% 0px' }}>
                    <AllImage  mw="40%" mw600="80%" src={buy}/>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '10px 0px' }}>
                        <Paragraph ta='left' fs600='15px' data-aos='fade-up'>- Download Metamask or your wallet of choice from the app store or Google Play store for free. Desktop users, download the Google Chrome extension by going to metamask.io.</Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '10px 0px' }}>
                        <Paragraph ta='left' fs600='15px' data-aos='fade-up'>- Have ETH in your wallet to switch to $WAGYU. If you don’t have any ETH, you can buy directly on Metamask, transfer from another wallet, or buy on another exchange and send it to your wallet.</Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '10px 0px' }}>
                        <Paragraph ta='left' fs600='15px' data-aos='fade-up'>- Switch ETH for $WAGYU. We have 0% taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.</Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '10px 0px' }}>
                        <Paragraph ta='left' fs600='15px' data-aos='fade-up'>- CA:- 00000000000000Xx0000x0000xxxx0</Paragraph>
                    </Grid>
                </Grid>
            </Container>
            <BackgroundDiv>
                <Container maxWidth='lg'>
                    <Grid container>
                        <GridStyle item xs={12}>
                            <IFrame
                                title="uniswap"
                                src="https://app.uniswap.org/#/swap?theme=dark"
                                height="100%"
                                width="100%"
                            />
                        </GridStyle>
                        <ImageGrid item xs={12}>
                            <DogImage mw='20%' src={FrameDog} />
                        </ImageGrid>
                    </Grid>
                </Container>
            </BackgroundDiv>
        </div>
    )
}

export default Buy;