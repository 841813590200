import styled from "@emotion/styled";
import { Typography } from '@mui/material';

const AllImage = styled.img`
    width: 100%;
    height: auto;
    max-width: ${props => props.mw? props.mw : ''};
    @media (max-width: 600px){
        max-width: ${props => props.mw600? props.mw600 : ''}; 
    }
`
const UList = styled.ul`
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: center;
    gap: 0px 20px;
    padding: 10px;
    @media (max-width: 750px){
        padding: 20px 0px;
    }
`
const List = styled.li`
    list-style: none;
    padding: ${props => props.p? props.p : '0px 15px'};
`
const ListItem = styled.a`
    text-decoration: none;
    color: #FFFFFF;
    font-size: ${props => props.fs? props.fs : '18px'};
    font-family: manjariRegular;
    cursor: pointer;
    transition: .6s;
    :hover {
        color: #DD3931;
    }
    @media (max-width: 750px){
        padding: 20px 0px;
        font-size: ${props => props.fs700? props.fs700 : ''};
    }
`
const Paragraph = styled(Typography)`
    color: ${props=>props.c? props.c : "white"};
    font-size: ${props=>props.fs? props.fs : "16px"};
    padding: ${props=> props.pd? props.pd : ""};
    text-align: ${props=>props.ta? props.ta : "center"};
    max-width: ${props=>props.mw? props.mw : ''};
    font-family: ${props=>props.fm? props.fm : 'manjariRegular'};;
    font-weight: ${props=>props.fw? props.fw : ''};
    @media (max-width: 600px){
       font-size: ${props=>props.fs600? props.fs600 : ''}
    }
`
const HeadingStyle = styled(Typography)`
    color: white;
    text-align: center;
    font-family: manjariBold;
    text-align: ${props=>props.ta? props.ta : "center"};
    @media (max-width: 600px){
       font-size: ${props=>props.fs600? props.fs600 : '60px'}
    }
`
export{AllImage, UList, List, ListItem, Paragraph, HeadingStyle};