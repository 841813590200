import React from 'react'
import { Container, Grid } from '@mui/material';
import { Paragraph } from '../../styling/style';
import styled from '@emotion/styled';

const ABoutGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
`
const GridContainer = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
`
const MainContainer = styled(Container)`
    display: flex;
    min-height: 100vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 600px){
        min-height: 80vh;
    }
`
function About() {
    return (
        <MainContainer id='about' maxWidth='lg'>
            <GridContainer container>
                <ABoutGrid item xs={12}>
                    <Paragraph fs600='15px' fw='500' fs='35px' data-aos="fade-up">🥩In my eighth year, a barkday tale,🥩
                        <br />No grand party, a different trail.
                        <br />Homebound joy with pawrents dear,
                        <br />Wagyu steak, a feast sincere.
                    </Paragraph>
                </ABoutGrid>
                <ABoutGrid item xs={12}>
                    <Paragraph fs600='15px' fw='500' fs='35px' data-aos="fade-up">First taste, WAGYU's divine grace,
                        <br />A savory dance, a tender embrace.
                        <br />No grand fuss, just love and cheer,
                        <br />🥩In my heart, WAGYU's premiere.🥩
                    </Paragraph>
                </ABoutGrid>
            </GridContainer>
        </MainContainer>
    )
}

export default About;