import React, { useEffect, useState } from 'react';
import { List, ListItem, UList } from '../styling/style';
import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import HeaderBg from '../assets/Headerbg.png';
import Header from '../sections/1-Header';
import About from '../sections/2-About';
import Contract from '../sections/3-Contract';
import RoadMap from '../sections/4-Roadmap';
import Buy from '../sections/5-Buy';
import HeaderBg2 from '../assets/Headerbg1.png';
import Last from '../sections/6-Last';
import AOS from "aos";
import "aos/dist/aos.css";
import Aos from 'aos';

const BackgroundDiv = styled.div`
    background-image: url(${HeaderBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    transition: transform 0.3s ease-in-out;
    transform: translateY(${props => `-${Math.min(props.scrollPosition * 0.1, -5)}vh`});
    @media (max-width: 750px){
        background-image: url(${HeaderBg2});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 100vh;
    }
    @media (max-width: 400px){
        min-height: 90vh;
    }
`

const MenuGrid = styled(Grid)`
    display: flex;
    justify-content: center;
`
const Main = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        Aos.init({
            duration: 800,
            offset: 200,
        });

        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        AOS.refresh();
    });

    const smoothScrollTo = (elementId) => {
        document.getElementById(elementId)?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    return (
        <div style={{ backgroundColor: 'black' }}>
            <BackgroundDiv scrollPosition={scrollPosition}>
                <Container maxWidth='lg'>
                    <Grid container>
                        <MenuGrid xs={12}>
                            <UList dat-aos='fade-up'>
                                <List onClick={() => smoothScrollTo('home')}><ListItem>Home</ListItem></List>
                                <List onClick={() => smoothScrollTo('about')}><ListItem>About</ListItem></List>
                                <List onClick={() => smoothScrollTo('tokenomics')}><ListItem>Tokenomics</ListItem></List>
                                <List onClick={() => smoothScrollTo('roadmap')}><ListItem>Roadmap</ListItem></List>
                                <List onClick={() => smoothScrollTo('buy')}><ListItem>How To Buy</ListItem></List>
                            </UList>
                        </MenuGrid>
                    </Grid>
                </Container>
                <Header />
            </BackgroundDiv>
            <About />
            <Contract />
            <RoadMap />
            <Buy />
            <Last />
        </div>
    )
}

export default Main;
