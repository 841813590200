import React, { useEffect, useState } from 'react';
import HeaderDog from '../../assets/headerdog.png';
import { AllImage } from '../../styling/style';
import styled from '@emotion/styled';
import { Container, Grid, Button } from '@mui/material';
import Twitter from '../../assets/twitter.png';
import Telegram from '../../assets/telegram.png';
import Ether from '../../assets/ether.png';
import Header1 from '../../assets/header1.png';
import Header2 from '../../assets/header2.png';
import AOS from "aos";
import "aos/dist/aos.css";
import Aos from 'aos';

const HeaderContainer = styled.div`
   display: flex;
  flex-direction: column;
  align-items: center;

`;

const HeaderGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px 20px;
  margin: auto;
  transition: transform 0.4s ease-in-out;
  transform: translateY(${props => (props.scrolled ? '-30%' : '0')});
  @media (max-width: 750px) {
    display: none;
  }
`;

const ButtonGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
`;

const SmallScrennGrid = styled(Grid)`
  display: none;
  @media (max-width: 750px) {
    display: block;
    margin: 20px 0px;
  }
`;

const SmallGridImage = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
`;

function Header() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    Aos.init({
      duration: 800,
      offset: 200,
    });

    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    AOS.refresh();
  });

  return (
    <HeaderContainer id="home">
      <Container maxWidth="lg">
        <Grid container data-aos="zoom-in">
          <HeaderGrid item xs={12} scrolled={scrolled}>
            <AllImage mw="45%" src={HeaderDog} />
          </HeaderGrid>
          <SmallScrennGrid container>
            <SmallGridImage item xs={12}>
              <AllImage mw="50%" src={Header1} />
            </SmallGridImage>
            <SmallGridImage item xs={12}>
              <AllImage mw="50%" src={HeaderDog} />
            </SmallGridImage>
            <SmallGridImage item xs={12}>
              <AllImage mw="50%" src={Header2} />
            </SmallGridImage>
          </SmallScrennGrid>
        </Grid>
      </Container>
      <ButtonGrid data-aos="fade-in">
        <Button href='https://twitter.com/wagyuinu'>
          <AllImage src={Twitter} />
        </Button>
        <Button href='https://t.me/wagyuinu' >
          <AllImage src={Telegram} />
        </Button>
        <Button>
          <AllImage src={Ether} />
        </Button>
      </ButtonGrid>
    </HeaderContainer>
  );
}

export default Header;
