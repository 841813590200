import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Aos from 'aos';

const BoxStyle = styled(Box)`
    min-height: ${props => props.minh ? props.minh : ''};
    max-width: ${props => props.mw ? props.mw : '250px'} ;
    border-radius: 20px 20px;
    padding: ${props => props.pd ? props.pd : '20px'};
`
const Para = styled(Typography)`
    font-family: manjariRegular;
    color: white;
    font-size: 14px;
    padding: 5px 0px;
    margin: ${props => props.m ? props.m : ''};
`
const Para1 = styled(Typography)`
    font-family: manjariBold;
    color: white;
    background: ${props => props.bg ? props.bg : ''} ;
    border-radius: 10px;
    padding: ${props => props.p ? props.p : ''};
`
const Disc = (props) => <Para>{props.detail}</Para>
const Cards = (props) => {
    const { tag, image, data } = props;
    useEffect(() => {
        Aos.init({
            duration: 800,
            offset: 200,
        });
    }, []);

    useEffect(() => {
        AOS.refresh();
    });
    return (
        <BoxStyle xs={12} md={4} {...props} data-aos="zoom-in-up" data-aos-duration='1000'>
            <Para1 variant='h4' {...props}>{tag}</Para1>
            <div>
                {data?.map((val, ind) => {
                    return <Disc key={ind} detail={val[0]} />
                })}
            </div>
        </BoxStyle>
    )
}

export default Cards;