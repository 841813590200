import React from 'react';
import { Container, Grid, Button } from '@mui/material';
import { AllImage, HeadingStyle, List, ListItem, Paragraph } from '../../styling/style';
import LastLogo from '../../assets/last.png';
import Twitter from '../../assets/twitter.png';
import Telegram from '../../assets/telegram.png';

function Last() {
    return (
        <div>
            <Container maxWidth='lg'>
                <Grid container>
                    <Grid item xs={12} sm={8} sx={{ padding: '20px 0px' }}>
                        <AllImage mw='70px' src={LastLogo} />
                        <Paragraph ta='left' pd='20px 0px' mw='300px'>Wagyu Inu, a project merging canine fascination with blockchain innovation. Witness the enduring legacy of WAGYU in the crypto space.</Paragraph>
                    </Grid>
                    <Grid item xs={6} sm={2} sx={{ padding: '40px 0px 10px' }}>
                        <HeadingStyle variant='h6' ta='left' fs600='20px'>Quick Links</HeadingStyle>
                        <List>
                            <ListItem fs='16px' fs700='14px' href='#home'>Home</ListItem>
                        </List>
                        <List>
                            <ListItem fs='16px' fs700='14px' href="#about">About</ListItem>
                        </List>
                        <List>
                            <ListItem fs='16px' fs700='14px' href="#tokenomics">Tokenomics</ListItem>
                        </List>
                        <List>
                            <ListItem fs='16px' fs700='14px' href="#roadmap">Roadmap</ListItem>
                        </List>
                        <List>
                            <ListItem fs='16px' fs700='14px' href="#buy">How To Buy</ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6} sm={2} sx={{ padding: '40px 0px 10px', display: 'flex', justifyContent: 'start', flexDirection: 'column', alignItems: 'center' }}>
                        <HeadingStyle variant='h6' ta='left' fs600='20px'>Socials</HeadingStyle>
                        <Button href='https://twitter.com/wagyuinu'><AllImage mw='70%' src={Twitter} /></Button>
                        <Button href='https://t.me/wagyuinu'><AllImage mw='70%'  src={Telegram} /></Button>
                    </Grid>
                </Grid>
            </Container>
            <div style={{ backgroundColor: 'white' }}>
                <Paragraph c='black' pd='7px 0px 4px' fs600='12px'>© 2023 Wagyu Inu Token. All Rights Reserved</Paragraph>
            </div>
        </div>
    )
}

export default Last